<!-- 七乐彩基本走势图 -->
<template>
    <div class="">
        <div class="ssq-table">
            <div class="ssq-table-header">
                <div class="ssq-icon">
                    <svg t="1621310782405" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="2538" width="45" height="45">
                        <path
                            d="M541.408711 157.6704h122.3936l44.347733-84.1216-199.529244-1.803378-266.146133 660.383289h63.644444z"
                            fill="#ffffff" p-id="2539"></path>
                        <path
                            d="M585.764978 271.402667h127.689955l44.828445-90.641067h-203.374934l-264.220444 666.843022h61.715911z"
                            fill="#ffffff" p-id="2540"></path>
                        <path
                            d="M813.343289 294.408533h-210.759111l-270.427022 657.846045h209.251555l90.643911-205.960534h126.2336s207.414044 9.910044 234.416356-232.490666c0 0 11.5712-160.924444-179.359289-219.394845z m1.823289 260.667734c-49.169067 80.275911-133.046044 52.315022-133.046045 52.315022l65.558756-159.647289c116.656356 6.764089 67.487289 107.335111 67.487289 107.332267zM203.901156 506.587022c40.4992-92.020622 128.253156-54.129778 128.253155-54.129778l69.415822-158.048711c0-14.819556-323.487289-52.562489-369.316977 239.246223-17.3568 176.822044 177.874489 204.694756 177.874488 204.694755l51.646578-119.893333c-96.079644-59.630933-57.873067-111.869156-57.873066-111.869156z"
                            fill="#ffffff" p-id="2541"></path>
                    </svg>
                    <div class="ssq-one">
                        <h2>中国福利彩票</h2>
                    </div>
                    <div class="ssq-two">
                        <h3>七 乐 彩 走 势 图</h3>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <table class="tab" border="1" cellspacing="0" cellpadding="0">
                <thead class="thead-fixed">
                    <tr>
                        <th rowspan="2" class="ranks_a">期号</th>
                        <!-- <th rowspan="2" class="ranks_a1">星期</th> -->
                        <th colspan="10" class="ranks_b">一区</th>
                        <th colspan="10" class="ranks_b">二区</th>
                        <th colspan="10" class="ranks_b">三区</th>
                        <!-- <th colspan="16" class="ranks_b">蓝球</th> -->
                        <th colspan="10" class="ranks_a1">尾数走势图</th>
                        <th colspan="3" class="ranks_b">一区012路</th>
                        <th colspan="3" class="ranks_b">二区012路</th>
                        <th colspan="3" class="ranks_b">三区012路</th>
                        <th colspan="5" class="ranks_b">尾数五行走势图</th>
                    </tr>
                    <tr>
                        <th class="ranks_c" v-for="(item , i) in 30" :key="i">{{item}}</th>
                        <th class="ranks_c" v-for="(item , i) in lan" :key="i">{{item}}</th>
                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">金</th>
                        <th class="ranks_c">木</th>
                        <th class="ranks_c">水</th>
                        <th class="ranks_c">火</th>
                        <th class="ranks_c">土</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item2 ,x) in qlc" :key='x'>
                        <td>{{item2.expect}}</td>
                        <!-- <td>{{}}</td> -->
                        <!-- 三区走势图 -->
                        <td class="b-lan" v-for="(item ,i) in red" :key='i'>
                            <div :class="item==item1&&item1!=item2.red[7] ? 'q-red' : (item==item1&&item1==item2.red[7]?'q-lan':'no')"
                                v-for="(item1 ,j) in item2.red" :key='j'>
                                {{item==item1 ? item : ''}}
                            </div>
                        </td>
                        <!-- 尾数走势图 -->
                        <td class="b-red" v-for="(item ,i) in lan" :key='i'>
                            <div :class="item1==item ? 'q-lan' : 'no' " v-for="(item1 ,j) in red0[x]" :key='j'>
                                {{item1==item ? item : '' }}
                            </div>
                        </td>
                        <!-- 012路 -->
                        <td class="b-lan" v-for="(item ,i) in lye" :key='i'>
                            <div :class="item1%3==item ? 'q-red' :'no'" v-for="(item1 ,j) in red1[x]" :key='j'>
                                {{ item1%3==item ? item :''}}
                            </div>
                        </td>
                        <!-- 012路 -->
                        <td class="b-red" v-for="(item ,i) in lye" :key='i'>
                            <div :class="item1%3==item ? 'q-lan' :'no'" v-for="(item1 ,j) in red2[x]" :key='j'>
                                {{ item1%3==item ? item :''}}
                            </div>
                        </td>
                        <!-- 012路 -->
                        <td class="b-lan" v-for="(item ,i) in lye" :key='i'>
                            <div :class="item1%3==item ? 'q-red' :'no'" v-for="(item1 ,j) in red3[x]" :key='j'>
                                {{ item1==item ? item :''}}
                            </div>
                        </td>
                        <!-- 五行走势图 -->
                        <td class="b-red" v-for="(item ,i) in wx" :key='i'>
                            <div :class="item ==item1? 'q-lan' : 'no'" v-for="(item1 ,j) in wx2[x]" :key='j'>
                                {{ item ==item1? item : '' }}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 54" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 54" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 54" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from 'vuex'
    import axios from 'axios'
    import config from '@/utils/config.js'
    //import '../js/font.js' //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'
    export default {
        name: 'qlc-basic',
        components: {

        },
        data() { // 数据源
            return {
                red: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
                lan: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
                red0: [],
                red1: [],
                red2: [],
                red3: [],
                ac: [],
                lye: [0, 1, 2],
                ysq: [1, 4, 7],
                ewb: [2, 5, 8],
                wx2: [],
                wx: ['金', '木', '水', '火', '土'],
                te: false
            }
        },
        mounted() {
            this.mssq50()
            this.mac()
            this.mred()
            this.mred1()
            this.mwx()
            this.toTop()
        },
        computed: {
            ...mapState(['qlc'])

        },
        methods: { // 函数体部分，js的主要逻辑控制  
            ...mapMutations(['mutationqlc']),
            ...mapActions(['actionqlc']),
            toTop() {
                document.documentElement.scrollTop = 0;
            },
            mssq50() {//走势图
                if (this.qlc == "") {
                    axios({
                        method: "post",
                        url: config.urlcom + "lottery/demo/findj",
                        data: {
                            'tab': "qlc",
                            'zd': "issueno",
                            'i': 50,
                        },
                    }).then(  //this.url是我在上面定义的一个固定的url
                        (res) => {
                            var data = []
                            var x = res.data.reverse()
                            x.forEach((item) => {
                                var red = item.number.split(' ')
                                red = red.concat(item.refernumber)
                                var obj = {
                                    "expect": item.issueno,
                                    "red": red,
                                }
                                data.push(obj)
                            })
                            this.actionqlc(data)
                        },
                    )
                    //alert('重新请求数据basic页')
                } else {
                    // alert('vux返回数据basic页')
                    return this.$store.state.qlc;
                }
            },
            mred() { //尾数去重统计
                this.red0 = []
                for (var i = 0; i < this.qlc.length; i++) {
                    var arr = new Array()
                    for (var j = 0; j < this.qlc[i].red.length; j++) {
                        if (arr.indexOf(this.qlc[i].red[j] % 10) == -1) {
                            arr.push(parseInt(this.qlc[i].red[j] % 10))
                        }
                    }
                    this.red0.push(arr)
                }
            },
            mred1() {//012路
                this.red1 = []
                this.red2 = []
                this.red3 = []
                for (var i = 0; i < this.qlc.length; i++) {
                    var arr = new Array()
                    var arr2 = new Array()
                    var arr3 = new Array()
                    for (var j = 0; j < this.qlc[i].red.length; j++) {
                        if (this.qlc[i].red[j] < 11 && arr.indexOf(this.qlc[i].red[j] % 10 % 3) == -1) {
                            arr.push(this.qlc[i].red[j] % 10 % 3)
                        }
                        if (10 < this.qlc[i].red[j] && this.qlc[i].red[j] < 21 && arr2.indexOf(this.qlc[i].red[j] % 10 % 3) == -1) {
                            arr2.push(this.qlc[i].red[j] % 10 % 3)
                        }
                        if (20 < this.qlc[i].red[j] && arr3.indexOf(this.qlc[i].red[j] % 10 % 3) == -1) {
                            arr3.push(this.qlc[i].red[j] % 10 % 3)
                        }
                    }
                    this.red1.push(arr)
                    this.red2.push(arr2)
                    this.red3.push(arr3)

                }
            },
            mac() {//AC值
                this.ac = []
                for (var i = 0; i < this.qlc.length; i++) {
                    var arr = []
                    for (var j = 0; j < this.qlc[i].red.length - 1; j++) {
                        for (var y = j; y < 5; y++) {
                            arr.push(Math.abs(this.qlc[i].red[j] - this.qlc[i].red[y + 1]))
                        }
                    }
                    var newArr = [];
                    for (var x = 0; x < arr.length; x++) {
                        if (newArr.indexOf(arr[x]) == -1) {
                            newArr.push(arr[x]);
                        }
                    }
                    this.ac.push((newArr.length - (6 - 1)) % 10)
                }

            },

            mwx() {//五行
                this.wx2 = []
                for (var i = 0; i < this.red0.length; i++) {
                    var arr = new Array();
                    for (var j = 0; j < this.red0[i].length; j++) {
                        if (this.red0[i][i, j] == 1 || this.red0[i][i, j] == 9) {
                            if (arr.indexOf('金') == -1) {
                                arr.push('金')
                            }
                        } if (this.red0[i][i, j] == 2 || this.red0[i][i, j] == 8) {
                            if (arr.indexOf('木') == -1) {
                                arr.push('木')
                            }
                        } if (this.red0[i][i, j] == 3 || this.red0[i][i, j] == 7) {
                            if (arr.indexOf('水') == -1) {
                                arr.push('水')
                            }
                        } if (this.red0[i][i, j] == 4 || this.red0[i][i, j] == 6) {
                            if (arr.indexOf('火') == -1) {
                                arr.push('火')
                            }
                        } if (this.red0[i][i, j] == 0 || this.red0[i][i, j] == 5) {
                            if (arr.indexOf('土') == -1) {
                                arr.push('土')
                            }
                        }

                    }

                    this.wx2.push(arr)

                    // console.log(this.wx2)
                }
            }




        },
        props: {
        },
        watch: {
            qlc() {
                this.mssq50()
                this.mac()
                this.mred()
                this.mred1()
                this.mwx()
            }
        }
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
    .ssq-table-header {
        width: 100%;
        height: 50px;
        background-color: red;
    }

    .tab {
        margin: 0 auto;
        border-collapse: collapse;
    }

    .thead-fixed th {
        border: 1px solid #808080;
    }

    .ranks_a {
        width: 60px;
    }

    .ranks_a1 {
        width: 25px;
    }

    .ranks_c {
        width: 23px;
        color: red;
    }

    .ranks_d {
        width: 22px;
        color: blue;
    }

    .ssq-icon {
        text-align: left;
    }

    .icon {
        float: left;
    }

    .ssq-one {
        float: left;
        height: 42px;
        line-height: 42px;
        color: #fff;

    }

    .ssq-two {
        float: left;
        width: 80%;
        text-align: center;
        line-height: 42px;
        font-size: 25px;
        color: rgb(252, 251, 249);
    }

    .b-red {
        height: 23px;
        background-color: rgb(215, 191, 224);
    }

    .b-lan {
        background-color: rgb(221, 220, 171);
    }

    .q-red {
        background-color: rgb(163, 45, 100);
        border-radius: 15px;
        color: #fff;
    }

    .q-lan {
        background-color: rgb(67, 67, 179);
        color: #fff;
        border-radius: 15px;
    }

    tbody .xuan {
        background-color: rgb(160, 156, 156);
        border-radius: 15px;
        color: rgb(253, 253, 253);
    }





    .no {
        display: none;
    }
</style>